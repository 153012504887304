import React from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import {Tooltip} from "recharts";

// Format number with decimal separator
function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

const WebAnalyticsTable = (props) => {
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell/>
                        <TableCell style={{textAlign: 'right', fontWeight: 700}}>Besuche (Hochrechnung)<Tooltip label="wurst">i</Tooltip></TableCell>
                        <TableCell style={{textAlign: 'right', fontWeight: 700}}>Besucher (Hochrechnung)</TableCell>
                        <TableCell style={{textAlign: 'right', fontWeight: 700}}>Seitenaufrufe (Hochrechnung)</TableCell>
                        <TableCell style={{textAlign: 'right', fontWeight: 700}}>Seitenaufrufe / Besuch</TableCell>
                        <TableCell style={{textAlign: 'right', fontWeight: 700}}>Verweildauer / Besuch</TableCell>
                        <TableCell style={{textAlign: 'right', fontWeight: 700}}>Bounce Rate</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{fontWeight: 700}}>7 Tage bis gestern</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatNumber(props.webAnalyticsData[0].sessions)} ({props.campaignLaunched ? formatNumber(Math.round(props.webAnalyticsData[0].sessions * props.trackingFactor)) : '-'})</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatNumber(props.webAnalyticsData[0].uniqueVisitors)} ({props.campaignLaunched ? formatNumber(Math.round(props.webAnalyticsData[0].uniqueVisitors * props.trackingFactor)) : '-'})</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{formatNumber(props.webAnalyticsData[0].pageImpressions)} ({props.campaignLaunched ? formatNumber(Math.round(props.webAnalyticsData[0].pageImpressions * props.trackingFactor)) : '-'})</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{(props.webAnalyticsData[0].pagesPerSession).toFixed(1) + ' Seiten'}</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{(props.webAnalyticsData[0].avgSessionDuration / 60).toFixed(2) + ' Minuten'}</TableCell>
                        <TableCell style={{textAlign: 'right'}}>{(props.webAnalyticsData[0].bounceRate).toFixed(2) + ' %'}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{fontWeight: 700}}>Seit Kampagnen-Launch</TableCell>
                        {props.campaignLaunched ?
                            <>
                                <TableCell style={{textAlign: 'right'}}>{formatNumber(props.webAnalyticsData[1].sessions) + ' (' + formatNumber(Math.round(props.webAnalyticsData[1].sessions * props.trackingFactor)) + ')'}</TableCell>
                                <TableCell style={{textAlign: 'right'}}>{formatNumber(props.webAnalyticsData[1].uniqueVisitors) + ' (' + formatNumber(Math.round(props.webAnalyticsData[1].uniqueVisitors * props.trackingFactor)) + ')'}</TableCell>
                                <TableCell style={{textAlign: 'right'}}>{formatNumber(props.webAnalyticsData[1].pageImpressions) + ' (' + formatNumber(Math.round(props.webAnalyticsData[1].pageImpressions * props.trackingFactor)) + ')'}</TableCell>
                                <TableCell style={{textAlign: 'right'}}>{(props.webAnalyticsData[1].pagesPerSession).toFixed(1) + ' Seiten'}</TableCell>
                                <TableCell style={{textAlign: 'right'}}>{(props.webAnalyticsData[1].avgSessionDuration / 60).toFixed(2) + ' Minuten'}</TableCell>
                                <TableCell style={{textAlign: 'right'}}>{(props.webAnalyticsData[1].bounceRate).toFixed(2) + ' %'}</TableCell>
                            </>
                        :
                            <TableCell colSpan={6} style={{textAlign: 'center'}}>Kampagne noch nicht gelauncht</TableCell>
                        }
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default WebAnalyticsTable;
