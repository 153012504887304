import React from "react";
import {Pie, PieChart, Cell, Label, Text} from "recharts";

const calculateGaugeValues = (targetValue, currentValue) => {
    let leftOverSlice;
    if (targetValue - currentValue < 0) {
        leftOverSlice = 0;
    } else {
        leftOverSlice = targetValue - currentValue;
    }
    return [{ value: currentValue}, { value: leftOverSlice }]
}

const calculateGaugeColors = (currentValue, targetValue, reverse = false) => {

    let currentGaugeColor;
    let availableGaugeColors = ['#d8279d','#ffa500','#13ac87']

    if (reverse) { availableGaugeColors = availableGaugeColors.reverse() }

    // Calculate current gauge color
    if (currentValue < (targetValue * 0.33)) {
        currentGaugeColor = availableGaugeColors[0];
    } else if (currentValue < (targetValue * 0.66)) {
        currentGaugeColor = availableGaugeColors[1];
    } else {
        currentGaugeColor = availableGaugeColors[2];
    }
    return currentGaugeColor;
}

const GaugeChart = props => {
    const currentValue = Number(props.currentValue);
    const targetValue = Number(props.targetValue);

    return (
        <PieChart height={260} width={440}>
            <Pie
                startAngle={210}
                endAngle={-30}
                innerRadius="56%"
                data={calculateGaugeValues(targetValue, currentValue)}
                label
                dataKey="value"
                blendStroke >
                <Label
                    content={props => {
                        const {viewBox: {cx, cy}} = props
                        const positioningProps = {
                            x: cx,
                            y: cy - 10,
                            textAnchor: 'middle',
                            verticalAnchor: 'middle',
                        }

                        return (
                            <>
                                <Text {...positioningProps} fontSize="2rem">{Math.round(1 / targetValue * currentValue * 100) + '%'}</Text>
                                <Text {...positioningProps} y={cy + 20}>{`von ${targetValue}`}</Text>
                            </>
                    )
                    }}
                />
                <Cell fill={calculateGaugeColors(currentValue, targetValue, props.reverse)} />
                <Cell fill="#aeaeae" />
            </Pie>
        </PieChart>
    );
};

export default GaugeChart;
