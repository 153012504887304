import React from 'react';
import {
    Create,
    Datagrid,
    DisabledInput,
    Edit,
    EditButton,
    List,
    SaveButton,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
} from 'react-admin';
import moment from 'moment';
import { Grid } from "@material-ui/core";
import CodeEditor from "../UI/CodeEditor";

const PagePanel = ({id, record, resource}) => (
    <div dangerouslySetInnerHTML={{__html: record.body}}/>
);

const CustomUrlField = ({ record, source }) => <a style={{fontSize: '1rem'}} href={`${process.env.REACT_APP_SITE_URL}/${record[source]}`} target="_blank" rel="noopener noreferrer">{record[source]}</a>;

const dateFormatter = v => {
    // v is a `Date` object
    return moment(v).format('DD.MM.YYYY HH:mm');
};

const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            label="Speichern"
            redirect="show"
            submitOnEnter={true}
        />
        <SaveButton
            label="Speichern und weitere erstellen"
            redirect={false}
            submitOnEnter={false}
            variant="flat"
        />
    </Toolbar>
);

export const PageList = (props) => (
    <List {...props}>
        <Datagrid expand={<PagePanel/>}>
            <CustomUrlField label="Slug" source="slug"/>
            <TextField label="Seitentitel" source="title"/>
            <TextField label="Veröffentlichung" source="creationDate" format={dateFormatter}/>
            <TextField label="Letzte Aktualisierung" source="lastUpdateDate" format={dateFormatter}/>
            <EditButton/>
        </Datagrid>
    </List>
);

export const PageEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Seitentitel" source="title"/>
                </Grid>
                <Grid item lg={3} md={6}>
                    <DisabledInput fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item lg={3} md={6}>
                    <DisabledInput fullWidth label="Veröffentlichung" source="creationDate" format={dateFormatter} />
                </Grid>
                <Grid item lg={3} md={6}>
                    <DisabledInput
                        fullWidth
                        label="Letzte Aktualisierung"
                        source="lastUpdateDate"
                        defaultValue={moment(new Date()).toISOString()}
                        format={dateFormatter}/>
                </Grid>
                <Grid item lg={3} md={6}>
                    <TextInput
                        fullWidth
                        label="Slug"
                        source="slug"/>
                </Grid>
                <Grid item xs={12}>
                    <CodeEditor source="body" label="Seiteninhalt"/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);

export const PageCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <Grid container>
                <Grid item xs={12}>
                    <TextInput fullWidth label="Seitentitel" source="title"/>
                </Grid>
                <Grid item lg={3} md={6}>
                    <DisabledInput fullWidth label="ID" source="_id"/>
                </Grid>
                <Grid item lg={3} md={6}>
                    <DisabledInput fullWidth label="Veröffentlichung" source="creationDate" format={dateFormatter} />
                </Grid>
                <Grid item lg={3} md={6}>
                    <DisabledInput
                        fullWidth
                        label="Letzte Aktualisierung"
                        source="lastUpdateDate"
                        defaultValue={moment(new Date()).toISOString()}
                        format={dateFormatter}/>
                </Grid>
                <Grid item lg={3} md={6}>
                    <TextInput
                        fullWidth
                        label="Slug"
                        source="slug"/>
                </Grid>
                <Grid item xs={12}>
                    <CodeEditor source="body" label="Seiteninhalt"/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Create>
);
