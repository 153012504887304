import React from 'react';
import phases from '../components/Phases';
import { Route } from 'react-router-dom';

export default [
    <Route
        key="phases"
        path="/phases"
        component={phases.edit} />
];
