import React from 'react';
import {
    Create,
    Datagrid,
    Edit,
    EditButton,
    EmailField,
    List,
    SelectInput,
    Show,
    SimpleForm,
    TextField,
    TextInput
} from 'react-admin';

const CustomTelephoneField = ({ record = {} }) => <a style={{fontSize: '1rem'}} href={`tel:${record.telephone}`} target="_blank" rel="noopener noreferrer">{record.telephone}</a>;

export const UserList = props => (
    <List {...props}>
        <Datagrid>
            <TextField label="ID" source="_id" />
            <TextField label="Rolle" source="role" />
            <TextField label="Anrede" source="salutation" />
            <TextField label="Vorname" source="firstname" />
            <TextField label="Nachname" source="lastname" />
            <EmailField style={{fontSize: '1rem'}} label="E-Mail" source="email" />
            <CustomTelephoneField label="Telefonnummer" source="telephone"/>
            <EditButton />
        </Datagrid>
    </List>
)

export const UserShow = props => (
    <Show {...props}>
        <SimpleForm>
            <TextField label="Autorisierung" source="role"/>
            <TextField label="Anrede" source="salutation" />
            <TextField label="Vorname" source="firstname" />
            <TextField label="Nachname" source="lastname" />
            <EmailField label="E-Mail" source="email" />
            <CustomTelephoneField addLabel={true} label="Telefonnummer" source="telephone"/>
        </SimpleForm>
    </Show>
);

export const UserEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <SelectInput label="Autorisierung" source="role" choices={[
                { id: 'contributor', name: 'Teilnehmer' },
                { id: 'superadmin', name: 'Super-Admin' },
            ]} />
            <TextInput label="Anrede" source="salutation" />
            <TextInput label="Vorname" source="firstname" />
            <TextInput label="Nachname" source="lastname" />
            <TextInput label="E-Mail" source="email" />
        </SimpleForm>
    </Edit>
);

export const UserCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <SelectInput label="Anrede" source="contactSalutation" choices={[
                { id: 'Frau', name: 'Frau' },
                { id: 'Herr', name: 'Herr' },
            ]} />
            <TextInput label="Vorname" source="contactFirstname" />
            <TextInput label="Nachname" source="contactLastname" />
            <TextInput label="E-Mail (Nutzername)" source="contactEmail" type="email" />
            <TextInput label="Passwort" source="contactPassword" type="password" />
        </SimpleForm>
    </Create>
);
