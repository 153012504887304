import React from 'react';
import { Layout, MenuItemLink, Responsive } from 'react-admin';
import SettingsIcon from '@material-ui/icons/SettingsApplications';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ProjectIcon from '@material-ui/icons/AssignmentInd';
import PageIcon from '@material-ui/icons/Description';
import CTAIcon from '@material-ui/icons/Announcement';
import UsersIcon from '@material-ui/icons/People';
import MenuItemsIcon from '@material-ui/icons/List';

const menuItems = [
    { name: 'phases', text: 'Wettbewerb', icon: <SettingsIcon/> },
    { name: 'cta', text: 'Calls-To-Action', icon: <CTAIcon/> },
    { name: 'menus', text: 'Navigation', icon: <MenuItemsIcon/> },
    { name: 'admin/projects', text: 'Projekte', icon: <ProjectIcon/> },
    { name: 'pages', text: 'Seiten', icon: <PageIcon/> },
    { name: 'users', text: 'Nutzer', icon: <UsersIcon/> },
];

const MyMenu = ({ onMenuClick, logout }) => (
    <>
        <MenuItemLink
            key='dashboard'
            to={'/'}
            primaryText='Übersicht'

            leftIcon={<DashboardIcon/>}
            onClick={onMenuClick}
        />
        { menuItems.map(item => (
            <MenuItemLink
                key={item.name}
                to={`/${item.name}`}
                primaryText={item.text}
                leftIcon={item.icon}
                onClick={onMenuClick}
            />
        ))}
        <Responsive
            small={logout}
            medium={null}
        />
    </>
);

const MyLayout = props => <Layout {...props} menu={MyMenu}/>;

export default MyLayout;
