import React from 'react';
import { addField } from 'react-admin';
import CodeMirror from 'react-codemirror';
import "codemirror/mode/xml/xml";

const CodeEditor2 = ({ input }) => (
        <CodeMirror
            value={input.value}
            onChange={val => input.onChange(val)}
            options={{
                mode: "xml",
                htmlMode: true,
                theme: "default",
                indentUnit: 4,
                lineNumbers: true,
                lineWrapping: true
            }}
        />
)

export default addField(CodeEditor2)
