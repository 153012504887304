import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarCheck, faEnvelopeOpenText, faFingerprint} from "@fortawesome/free-solid-svg-icons";

const styles = {
    paper: { padding: '6px 16px' },
};

const convertToLocalDate = (ISODate) => {
    let convertedDate = new Date(ISODate);
    return convertedDate.toLocaleString('de-DE');
}

const CampaignTimeline = (props) => {

    let campaignLaunch, voteStart, voteStartFinale, voteEnd;

    if(props.campaignLaunch) {
        campaignLaunch = convertToLocalDate(props.campaignLaunch);
        voteStart = convertToLocalDate(props.voteStart);
        voteStartFinale = convertToLocalDate(props.voteStartFinale);
        voteEnd = convertToLocalDate(props.voteEnd);
    }

    return (
        <Timeline style={{ maxWidth: 'calc(50% - 2rem)', padding: 0}} align="alternate">

            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="secondary">
                        <FontAwesomeIcon icon={faEnvelopeOpenText} size="1x" color="white" />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={styles.paper}>
                        <div style={styles.paper}>
                            <Typography variant="h6" component="h1">
                                Kampagnen-Launch
                            </Typography>
                            <Typography>{campaignLaunch}</Typography>
                        </div>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="secondary">
                        <FontAwesomeIcon icon={faFingerprint} size="1x" color="white" />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}>
                        <div style={styles.paper}>
                            <Typography variant="h6" component="h1">
                                Qualifikationsstart
                            </Typography>
                            <Typography>{voteStart}</Typography>
                        </div>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="secondary">
                        <FontAwesomeIcon icon={faEnvelopeOpenText} size="1x" color="white" />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={styles.paper}>
                        <div style={styles.paper}>
                            <Typography variant="h6" component="h1">
                                Qualifikationsende
                            </Typography>
                            <Typography>{voteStartFinale}</Typography>
                        </div>
                    </Paper>
                </TimelineContent>
            </TimelineItem>

            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color="secondary">
                        <FontAwesomeIcon icon={faCalendarCheck} size="1x" color="white" />
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3} className={styles.paper}>
                        <div style={styles.paper}>
                            <Typography variant="h6" component="h1">
                                Finalende
                            </Typography>
                            <Typography>{voteEnd}</Typography>
                        </div>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        </Timeline>
    )
};

export default CampaignTimeline;
