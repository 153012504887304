import React from 'react';
import { fetchUtils, Admin, Resource, Login } from 'react-admin';
import { PageList, PageEdit, PageCreate } from './components/Pages/Pages';
import { CTAList, CTAEdit, CTACreate } from './components/CallsToAction/CallsToAction';
import { ProjectList, ProjectEdit } from './components/Projects/Projects';
import { UserEdit, UserList, UserCreate, UserShow } from "./components/Users/Users";
import { MenusList, MenusEdit, MenusCreate } from "./components/Menus/Menus";
import { createTheme } from '@material-ui/core/styles';
import ProjectIcon from '@material-ui/icons/AssignmentInd';
import simpleRestProvider from './utilities/dataProvider';
import Dashboard from './components/Dashboard/Dashboard';
import PageIcon from '@material-ui/icons/Description';
import MenuItemsIcon from '@material-ui/icons/List';
import authProvider from "./utilities/authProvider";
import customRoutes from "./utilities/customRoutes";
import germanMessages from 'ra-language-german';
import MyLayout from "./components/UI/Menu/Menu";

const messages = {
    de: germanMessages
};
const i18nProvider = locale => messages[locale];

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('idToken');
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

const LoginPage = () => <Login backgroundImage="none" />;

const theme = createTheme({
    palette: {
        primary: {
            main: '#536DFE'
        },
        secondary: {
            main: '#3F51B5'
        }
    },
});

const App = () => (
    <Admin
        appLayout={MyLayout}
        theme={theme}
        dashboard={Dashboard}
        authProvider={authProvider}
        dataProvider={dataProvider}
        loginPage={LoginPage}
        locale="de"
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}>

        <Resource options={{ label: "Calls-To-Action" }} name="cta" list={CTAList} edit={CTAEdit} create={CTACreate} icon={PageIcon} />
        <Resource options={{ label: "Navigation" }} name="menus" list={MenusList} edit={MenusEdit} create={MenusCreate} icon={MenuItemsIcon} />
        <Resource options={{ label: "Projekte" }} name="admin/projects" list={ProjectList} edit={ProjectEdit} icon={ProjectIcon} />
        <Resource options={{ label: "Nutzer" }} name="users" list={UserList} edit={UserEdit} create={UserCreate} show={UserShow} icon={ProjectIcon} />
        <Resource options={{ label: "Seiten" }} name="pages" list={PageList} edit={PageEdit} create={PageCreate} icon={PageIcon} />
        <Resource name="qualificationvotes" />
        <Resource name="phases" />
    </Admin>
);

export default App;
