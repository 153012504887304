import {IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import React from "react";
import InfoIcon from "@material-ui/icons/Help";

const TimeOnConsentManagerTable = (props) => {
    return (
        <TableContainer>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{fontWeight: 700}}>Mittelwerttyp</TableCell>
                        <TableCell style={{fontWeight: 700}} align="right">Wert</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Mittelwert
                            <Tooltip title="Der Mittelwert beschreibt den statistischen Durchschnittswert.
                                            Für den Mittelwert addiert man alle Werte eines Datensatzes und teilt die
                                            Summe durch die Anzahl aller Werte.">
                                <IconButton size="small">
                                    <InfoIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell
                            align="right">{Math.round(props.meanClientDelta * 10) / 10} Sekunden</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Median
                            <Tooltip title="Der Wert, der genau in der Mitte einer Datenverteilung liegt, nennt sich Median">
                                <IconButton size="small">
                                    <InfoIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell
                            align="right">{Math.round(props.medianClientDelta * 10) / 10} Sekunden</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell component="th" scope="row">
                            Modus
                            <Tooltip title="Der Modus gibt an, welche Merkmalsausprägung in einem Datensatz am häufigsten vorkommt.">
                                <IconButton size="small">
                                    <InfoIcon fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell
                            align="right">{Math.round(props.modeClientDelta * 10) / 10} Sekunden</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default TimeOnConsentManagerTable;
