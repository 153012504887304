import React from 'react';
import { Edit, DateTimeInput, FormTab, TabbedForm } from 'react-admin';
import {Grid} from "@material-ui/core";

const PhasesEdit = ({ staticContext, ...props }) => {
    return (
        <Edit
            resource="phases"
            basePath="/phases"
            redirect={false}
            title="Wettbewerb"
            {...props}>
            <TabbedForm>
                <FormTab label="Phasen">
                    <Grid container spacing={2} md={4}>
                        <Grid item xs={12}>
                            <DateTimeInput fullWidth source="campaignLaunch" label="Kampagnen-Launch" step="1"/>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimeInput fullWidth helperText="ACHTUNG! Bei globalem SMS-Voting müssen Abstimmungsstart und Finalstartdatum identisch sein!" source="voteStart" label="Abstimmungsstart" step="1"/>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimeInput fullWidth helperText="ACHTUNG! Bei globalem SMS-Voting müssen Abstimmungsstart und Finalstartdatum identisch sein!" source="voteStartFinale" label="Finalstart" step="1"/>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimeInput fullWidth source="voteEnd" label="Abstimmungsende" step="1"/>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

export default PhasesEdit;
